(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/carousel/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/lb-ui/carousel/assets/javascripts/utils.js');
"use strict";


const DragState = Object.freeze({
  START: 'drag-start',
  DRAGGING: 'drag-active',
  END: 'drag-end',
  IDLE: 'drag-idle'
});
const MouseEvents = Object.freeze({
  START: 'mousedown',
  MOVE: 'mousemove',
  STOP: 'mouseup'
});
const TouchEvents = Object.freeze({
  START: 'touchstart',
  MOVE: 'touchmove',
  STOP: 'touchend'
});
const supportsTouch = () => {
  if ('ontouchstart' in window) {
    return true;
  }

  if (window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }
  return window.matchMedia('(pointer: coarse)').matches;
};
setGlobal('svs.components.lbUi.carousel.utils', {
  DragState,
  MouseEvents,
  TouchEvents,
  supportsTouch
});

 })(window);