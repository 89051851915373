(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/carousel/views/carousel-navigation.js') >= 0) return;  svs.modules.push('/components/lb-ui/carousel/views/carousel-navigation.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.lb_ui=_svs.lb_ui||{};
_svs.lb_ui.carousel=_svs.lb_ui.carousel||{};
_svs.lb_ui.carousel.templates=_svs.lb_ui.carousel.templates||{};
svs.lb_ui.carousel.templates.carousel_navigation = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "carousel-nav-disabled";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon-button",{"name":"ui","hash":{"data-testid":"carousel-navigation-previous","class":"carousel-nav-previous disabled js-carousel-navigation-previous"},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":5,"column":9}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon",{"name":"ui","hash":{"class":"js-carousel-navigation-previous-icon","size":"300","icon":"menu-left"},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":90}}}))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon-button",{"name":"ui","hash":{"data-testid":"carousel-navigation-previous","class":"carousel-nav-previous js-carousel-navigation-previous"},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":9,"column":9}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon-button",{"name":"ui","hash":{"data-testid":"carousel-navigation-next","class":"carousel-nav-next disabled js-carousel-navigation-next"},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":2},"end":{"line":17,"column":9}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon",{"name":"ui","hash":{"class":"js-carousel-navigation-previous-icon","size":"300","icon":"menu-right"},"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":16,"column":91}}}))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon-button",{"name":"ui","hash":{"data-testid":"carousel-navigation-next","class":"carousel-nav-next js-carousel-navigation-next"},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":2},"end":{"line":21,"column":9}}})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"carousel-nav carousel-nav-hidden js-carousel-navigation "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":68},"end":{"line":1,"column":112}}})) != null ? stack1 : "")
    + "\" data-testid=\"carousel-navigation\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"selectedIndex") : depth0),"===",0,{"name":"compare","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":10,"column":14}}})) != null ? stack1 : "")
    + "  <div class=\"carousel-nav-dots js-carousel-navigation-dots\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-carousel-carousel_navigation_item"),depth0,{"name":"components-lb_ui-carousel-carousel_navigation_item","hash":{"selectedIndex":0,"size":(depths[1] != null ? lookupProperty(depths[1],"size") : depths[1]),"index":0},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"selectedIndex") : depth0),"===",(lookupProperty(helpers,"zeroBasedPageLength")||(depth0 && lookupProperty(depth0,"zeroBasedPageLength"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"children") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),(depth0 != null ? lookupProperty(depth0,"itemsPerPage") : depth0),{"name":"zeroBasedPageLength","hash":{},"data":data,"loc":{"start":{"line":14,"column":33},"end":{"line":14,"column":83}}}),{"name":"compare","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":14,"column":2},"end":{"line":22,"column":14}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['components-lb_ui-carousel-carousel_navigation'] = svs.lb_ui.carousel.templates.carousel_navigation;
})(svs, Handlebars);


 })(window);