(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/carousel/assets/javascripts/carousel-manager.js') >= 0) return;  svs.modules.push('/components/lb-ui/carousel/assets/javascripts/carousel-manager.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }

const {
  onScrollEnd
} = svs.components.lbUtils;
const {
  breakpoints,
  BREAKPOINT_AM,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const {
  DragState,
  MouseEvents,
  TouchEvents,
  supportsTouch
} = svs.components.lbUi.carousel.utils;
var _container = new WeakMap();
var _navigation = new WeakMap();
var _navigationDots = new WeakMap();
var _prevButton = new WeakMap();
var _nextButton = new WeakMap();
var _indexButtons = new WeakMap();
var _parser = new WeakMap();
var _dragHandlers = new WeakMap();
var _deviceEvent = new WeakMap();
var _enabled = new WeakMap();
var _mobileIndex = new WeakMap();
var _isScrolling = new WeakMap();
var _view = new WeakMap();
var _mouse = new WeakMap();
var _itemsPerPage = new WeakMap();
var _options = new WeakMap();
var _touch = new WeakMap();
var _desktopBp = new WeakMap();
var _mobileBp = new WeakMap();
class CarouselManager {
  constructor(element) {
    var _classPrivateFieldGet2;
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    _classPrivateFieldInitSpec(this, _container, void 0);
    _classPrivateFieldInitSpec(this, _navigation, void 0);
    _classPrivateFieldInitSpec(this, _navigationDots, void 0);
    _classPrivateFieldInitSpec(this, _prevButton, void 0);
    _classPrivateFieldInitSpec(this, _nextButton, void 0);
    _classPrivateFieldInitSpec(this, _indexButtons, void 0);
    _classPrivateFieldInitSpec(this, _parser, new DOMParser());
    _classPrivateFieldInitSpec(this, _dragHandlers, []);
    _classPrivateFieldInitSpec(this, _deviceEvent, void 0);
    _classPrivateFieldInitSpec(this, _enabled, true);
    _classPrivateFieldInitSpec(this, _mobileIndex, 0);
    _classPrivateFieldInitSpec(this, _isScrolling, false);
    _classPrivateFieldInitSpec(this, _view, false);
    _classPrivateFieldInitSpec(this, _mouse, {
      x: 0,
      y: 0
    });
    _classPrivateFieldInitSpec(this, _itemsPerPage, void 0);
    _classPrivateFieldInitSpec(this, _options, {
      align: 'start',
      gutter: 1,
      isMobile: false
    });
    _classPrivateFieldInitSpec(this, _touch, void 0);
    _classPrivateFieldInitSpec(this, _desktopBp, "(min-width:".concat(breakpoints.values.get(BREAKPOINT_MD), "px)"));
    _classPrivateFieldInitSpec(this, _mobileBp, "(max-width:".concat(breakpoints.values.get(BREAKPOINT_AM) - 0.02, "px)"));
    _defineProperty(this, "numberOfPages", void 0);
    _defineProperty(this, "children", []);
    _defineProperty(this, "index", 0);
    _defineProperty(this, "placeholders", []);
    _defineProperty(this, "status", DragState.IDLE);
    _classPrivateFieldSet(_container, this, element.querySelector('.js-carousel-items'));
    this.el = element;
    this.children = element.querySelectorAll('.js-carousel-item');
    _classPrivateFieldSet(_options, this, this.mergeOptions(_classPrivateFieldGet(_options, this), options));

    ['mergeOptions', 'createElementFromHTML', 'handleTransforms', 'init', 'setupStyles', 'setupPlaceholders', 'setupNavigation', 'enable', 'disable', 'getLocationFromEvent', 'updateInteraction', 'updateNavigation', 'transformCards', 'getPositionForIndex', 'moveToPageIndex', 'onRelease', 'onStart', 'onMove', 'onEnd', 'onResize', 'onPrevious', 'onNext', 'onIndex', 'addListeners', 'removeListeners', 'destroy'].forEach(fn => {
      this[fn] = this[fn].bind(this);
    });
    const {
      selectedIndex
    } = _classPrivateFieldGet(_options, this);
    _classPrivateFieldSet(_itemsPerPage, this, (_classPrivateFieldGet2 = _classPrivateFieldGet(_options, this)) === null || _classPrivateFieldGet2 === void 0 ? void 0 : _classPrivateFieldGet2.itemsPerPage);
    this.setupStyles();
    this.setupNavigation();
    this.onResize();
    this.index = this.children.length > _classPrivateFieldGet(_itemsPerPage, this) && selectedIndex > 0 ? Math.floor(selectedIndex / _classPrivateFieldGet(_itemsPerPage, this)) : 0;
    this.updateInteraction();
    this.addListeners();
    this.init();
  }
  mergeOptions(a, b) {
    return Object.keys(_objectSpread(_objectSpread({}, a), b)).reduce((acc, key) => {
      acc[key] = b[key] || a[key];
      return acc;
    }, {});
  }
  createElementFromHTML(htmlString) {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    return div.firstChild;
  }
  handleTransforms() {
    this.transformCards(_classPrivateFieldGet(_container, this));
    if (!_classPrivateFieldGet(_isScrolling, this)) {
      _classPrivateFieldSet(_isScrolling, this, onScrollEnd(_classPrivateFieldGet(_container, this), () => {
        _classPrivateFieldSet(_isScrolling, this, false);
      }));
    }
  }
  init() {
    var _this$children;
    if (_classPrivateFieldGet(_view, this) === CarouselManager.MOBILE) {
      this.transformCards(_classPrivateFieldGet(_container, this));
      _classPrivateFieldGet(_container, this).addEventListener('scroll', this.handleTransforms);
    }

    if (_classPrivateFieldGet(_mobileIndex, this) !== null) {
      const readIndex = Math.floor(_classPrivateFieldGet(_mobileIndex, this) / _classPrivateFieldGet(_itemsPerPage, this));
      if (readIndex !== this.index) {
        this.index = readIndex;
      }
      _classPrivateFieldSet(_mobileIndex, this, null);
    }
    if (_classPrivateFieldGet(_view, this) === CarouselManager.MOBILE) {
      this.disable();
    } else {
      this.enable();
    }

    this.numberOfPages = Math.ceil(((_this$children = this.children) === null || _this$children === void 0 ? void 0 : _this$children.length) / _classPrivateFieldGet(_itemsPerPage, this));
    if (_classPrivateFieldGet(_view, this) === CarouselManager.MOBILE) {
      setTimeout(() => {
        if (_classPrivateFieldGet(_container, this).scrollLeft === 0) {
          _classPrivateFieldGet(_container, this).scrollTo({
            left: 20,
            behavior: 'smooth'
          });
        }
      }, 100);
    }
  }
  setupStyles() {
    const {
      align,
      cardSize,
      gutter
    } = _classPrivateFieldGet(_options, this);
    this.el.style.setProperty('--carousel-align', align);
    this.el.style.setProperty('--carousel-gutter', gutter);
    this.el.style.setProperty('--carousel-card-size', cardSize ? "".concat(cardSize, "px") : '');
    if (_classPrivateFieldGet(_itemsPerPage, this)) {
      this.el.style.setProperty('--carousel-per-page', _classPrivateFieldGet(_itemsPerPage, this));
    }
  }
  setupPlaceholders() {
    const {
      usePlaceholders,
      useSkeleton
    } = _classPrivateFieldGet(_options, this);
    if (usePlaceholders || useSkeleton) {
      var _this$children2;
      this.placeholders = [...Array(Math.max(0, _classPrivateFieldGet(_itemsPerPage, this) - ((_this$children2 = this.children) === null || _this$children2 === void 0 ? void 0 : _this$children2.length))).keys()].map(() => _classPrivateFieldGet(_parser, this).parseFromString("\n        <div class=\"carousel-item\">\n          <div class=\"carousel-item-inner\">\n            <div className=\"carousel-placeholder-item\" />\n          </div>\n        </div>\n      "));
    }
  }
  setupNavigation() {
    _classPrivateFieldSet(_navigation, this, this.el.querySelector('.js-carousel-navigation'));
    _classPrivateFieldSet(_navigationDots, this, this.el.querySelector('.js-carousel-navigation-dots'));
    _classPrivateFieldSet(_prevButton, this, this.el.querySelector('.js-carousel-navigation-previous'));
    _classPrivateFieldSet(_nextButton, this, this.el.querySelector('.js-carousel-navigation-next'));
    this.updateNavigation();
  }
  enable() {
    this.enabled = true;
  }
  disable() {
    this.enabled = false;
  }
  getLocationFromEvent(e) {
    return {
      x: _classPrivateFieldGet(_touch, this) ? e.changedTouches[0].clientX : e.pageX,
      y: _classPrivateFieldGet(_touch, this) ? e.changedTouches[0].clientY : e.pageY
    };
  }
  updateInteraction(touch) {
    _classPrivateFieldSet(_touch, this, touch);
    _classPrivateFieldSet(_deviceEvent, this, touch ? TouchEvents : MouseEvents);
  }
  updateNavigation() {
    this.numberOfPages = Math.ceil(this.children.length / _classPrivateFieldGet(_itemsPerPage, this)) || 1;
    if (this.numberOfPages <= 1) {
      _classPrivateFieldGet(_navigation, this).classList.add('carousel-nav-hidden');
    } else {
      _classPrivateFieldGet(_navigation, this).classList.remove('carousel-nav-hidden');
    }
    if (_classPrivateFieldGet(_view, this) !== CarouselManager.MOBILE && _classPrivateFieldGet(_navigationDots, this).children.length - 2 !== this.numberOfPages) {
      _classPrivateFieldGet(_navigationDots, this).innerHTML = '';
      if (this.numberOfPages > 1) {
        for (let i = 0; i < this.numberOfPages; i++) {
          const dot = this.createElementFromHTML("\n            <button class=\"icon-button carousel-nav-item js-carousel-navigation-item\">\n              <span class=\"carousel-nav-icon carousel-nav-icon-".concat(_classPrivateFieldGet(_options, this).size || 300, "\" />\n            </button>\n          "));
          _classPrivateFieldGet(_navigationDots, this).appendChild(dot);
          dot.addEventListener('click', this.onIndex);
        }
      }
    }
    _classPrivateFieldSet(_indexButtons, this, [...this.el.querySelectorAll('.js-carousel-navigation-item')]);
    [..._classPrivateFieldGet(_indexButtons, this)].forEach((item, i) => {
      item.dataset.index = i;
    });
    this.index = Math.min(this.index, this.numberOfPages - 1);
    _classPrivateFieldGet(_indexButtons, this).forEach(btn => {
      if (parseInt(btn.dataset.index, 10) === this.index) {
        btn.classList.add('carousel-nav-item-active');
      } else {
        btn.classList.remove('carousel-nav-item-active');
      }
    });
    if (this.index === 0) {
      _classPrivateFieldGet(_prevButton, this).classList.add('disabled');
    } else {
      _classPrivateFieldGet(_prevButton, this).classList.remove('disabled');
    }
    if (this.index === this.numberOfPages - 1) {
      _classPrivateFieldGet(_nextButton, this).classList.add('disabled');
    } else {
      _classPrivateFieldGet(_nextButton, this).classList.remove('disabled');
    }
  }

  transformCards(el) {
    if (el) {
      const parentBounds = el.getBoundingClientRect();
      const parentCenter = Math.round(parentBounds.left + parentBounds.width / 2);
      for (let i = 0; i < el.children.length; i++) {
        const card = el.children[i];
        const cardBounds = card.getBoundingClientRect();
        const cardCenter = Math.round(cardBounds.left + cardBounds.width / 2);
        const diff = Math.abs(cardCenter - parentCenter);
        card.firstElementChild.style.setProperty('--offset', Math.min(1, Math.round(diff / cardBounds.width * 100) / 100));
        if (diff < 50) {
          _classPrivateFieldSet(_mobileIndex, this, i);
        }
      }
    }
  }

  getPositionForIndex(index) {
    const children = [...(this.children || [])];
    if (!children.length) {
      return -1;
    }
    const bounds = children[0].getBoundingClientRect();
    const n = Math.min(children.length - _classPrivateFieldGet(_itemsPerPage, this), index * _classPrivateFieldGet(_itemsPerPage, this));
    return n === 0 ? -1 : Math.max(0, n * bounds.width);
  }

  moveToPageIndex(index) {
    let instant = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const x = this.getPositionForIndex(Number.isInteger(index) ? index : this.index);
    this.index = index;
    this.updateNavigation();
    if (_classPrivateFieldGet(_container, this).scrollLeft !== x) {
      _classPrivateFieldGet(_container, this).scrollTo({
        left: x,
        behavior: instant ? 'instant' : 'smooth'
      });
    }
  }

  onRelease(index) {
    window.removeEventListener(_classPrivateFieldGet(_deviceEvent, this).MOVE, this.onMove);
    window.removeEventListener(_classPrivateFieldGet(_deviceEvent, this).STOP, this.onEnd);
    _classPrivateFieldSet(_isScrolling, this, false);
    this.index = index;
    this.moveToPageIndex(index);
  }

  onStart(e) {
    if (!_classPrivateFieldGet(_enabled, this) || e.target.closest('button') || e.target.closest('a')) {
      return;
    }
    if (e.type === MouseEvents.START) {
      e.preventDefault();
    }
    window.removeEventListener(_classPrivateFieldGet(_deviceEvent, this).MOVE, this.onMove);
    window.removeEventListener(_classPrivateFieldGet(_deviceEvent, this).STOP, this.onEnd);
    window.addEventListener(_classPrivateFieldGet(_deviceEvent, this).MOVE, this.onMove, {
      passive: false
    });
    window.addEventListener(_classPrivateFieldGet(_deviceEvent, this).STOP, this.onEnd, {
      passive: false
    });
    _classPrivateFieldSet(_mouse, this, this.getLocationFromEvent(e));
    _classPrivateFieldSet(_isScrolling, this, true);
    this.status = DragState.START;
    requestAnimationFrame(() => {
      this.status = DragState.DRAGGING;
    });
  }

  onMove(e) {
    if (_classPrivateFieldGet(_enabled, this)) {
      e.preventDefault();
    }
    if (_classPrivateFieldGet(_view, this) !== CarouselManager.MOBILE) {
      const p = this.getLocationFromEvent(e);
      const dist = {
        x: p.x - _classPrivateFieldGet(_mouse, this).x,
        y: p.y - _classPrivateFieldGet(_mouse, this).y
      };
      if (Math.abs(dist.x) > CarouselManager.DRAG_THRESHOLD) {
        const newIndex = dist.x > 0 ? Math.max(0, this.index - 1) : Math.min(this.numberOfPages - 1, this.index + 1);
        this.onRelease(newIndex);
      }
    }
  }

  onEnd(e) {
    if (_classPrivateFieldGet(_enabled, this) && e.type === MouseEvents.START) {
      e.preventDefault();
    }
    window.removeEventListener(_classPrivateFieldGet(_deviceEvent, this).MOVE, this.onMove);
    window.removeEventListener(_classPrivateFieldGet(_deviceEvent, this).STOP, this.onEnd);
    this.status = DragState.END;
    requestAnimationFrame(() => {
      this.status = DragState.IDLE;
    });
    _classPrivateFieldSet(_isScrolling, this, false);
  }
  onResize() {
    const touch = supportsTouch();
    const {
      isMobile,
      itemsPerPage
    } = _classPrivateFieldGet(_options, this);
    if (_classPrivateFieldGet(_touch, this) !== touch) {
      this.updateInteraction(touch);
    }
    if (touch && (window.matchMedia(_classPrivateFieldGet(_mobileBp, this)).matches || isMobile)) {
      if (_classPrivateFieldGet(_view, this) !== CarouselManager.MOBILE) {
        this.el.classList.add('carousel-mobile');
        _classPrivateFieldSet(_view, this, CarouselManager.MOBILE);
      }
    } else {
      const currentCount = _classPrivateFieldGet(_itemsPerPage, this);
      this.el.classList.remove('carousel-mobile');
      if (this.children.length > (itemsPerPage || 4)) {
        if (window.matchMedia(_classPrivateFieldGet(_desktopBp, this)).matches && _classPrivateFieldGet(_view, this) !== CarouselManager.DESKTOP) {
          _classPrivateFieldSet(_view, this, CarouselManager.DESKTOP);
          _classPrivateFieldSet(_itemsPerPage, this, itemsPerPage || 4);
        } else if (!window.matchMedia(_classPrivateFieldGet(_desktopBp, this)).matches && _classPrivateFieldGet(_view, this) !== CarouselManager.TABLET) {
          _classPrivateFieldSet(_view, this, CarouselManager.TABLET);
          _classPrivateFieldSet(_itemsPerPage, this, itemsPerPage || 3);
        }
      }
      if (currentCount !== _classPrivateFieldGet(_itemsPerPage, this)) {
        this.el.style.setProperty('--carousel-per-page', _classPrivateFieldGet(_itemsPerPage, this));
        this.updateNavigation();
      }
    }
  }
  onPrevious() {
    if (this.index > 0) {
      this.moveToPageIndex(this.index - 1);
    }
  }
  onNext() {
    if (this.index + 1 < this.numberOfPages) {
      this.moveToPageIndex(this.index + 1);
    }
  }
  onIndex(e) {
    const btn = e.target.closest('.js-carousel-navigation-item');
    const index = parseInt(btn === null || btn === void 0 ? void 0 : btn.dataset.index, 10);
    this.moveToPageIndex(index || 0);
  }
  addListeners() {
    window.addEventListener('resize', this.onResize);
    if (this.el && this.el instanceof Element) {
      _classPrivateFieldGet(_dragHandlers, this).push({
        key: _classPrivateFieldGet(_deviceEvent, this).START,
        handler: this.onStart
      });
      this.el.addEventListener(_classPrivateFieldGet(_deviceEvent, this).START, this.onStart, {
        passive: false
      });
      _classPrivateFieldGet(_prevButton, this).addEventListener('click', this.onPrevious);
      _classPrivateFieldGet(_nextButton, this).addEventListener('click', this.onNext);
      _classPrivateFieldGet(_indexButtons, this).forEach(btn => {
        btn.addEventListener('click', this.onIndex);
      });
    }
  }
  removeListeners() {
    window.removeEventListener('resize', this.onResize);
    _classPrivateFieldGet(_container, this).removeEventListener('scroll', this.handleTransforms);
    while (_classPrivateFieldGet(_dragHandlers, this).length) {
      var _this$el;
      const {
        key,
        handler
      } = _classPrivateFieldGet(_dragHandlers, this).pop();
      (_this$el = this.el) === null || _this$el === void 0 || _this$el.removeEventListener(key, handler);
    }
    _classPrivateFieldGet(_prevButton, this).removeEventListener('click', this.onPrevious);
    _classPrivateFieldGet(_nextButton, this).removeEventListener('click', this.onNext);
    _classPrivateFieldGet(_indexButtons, this).forEach(btn => btn.removeEventListener('click', this.onIndex));
  }
  destroy() {
    this.removeListeners();
  }
}
_defineProperty(CarouselManager, "DRAG_THRESHOLD", 50);
_defineProperty(CarouselManager, "MOBILE", 'mobile');
_defineProperty(CarouselManager, "TABLET", 'tablet');
_defineProperty(CarouselManager, "DESKTOP", 'desktop');
setGlobal('svs.components.lbUi.carousel.CarouselManager', CarouselManager);

 })(window);