(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/carousel/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/lb-ui/carousel/assets/javascripts/init.js');
"use strict";


const {
  CarouselManager
} = svs.components.lbUi.carousel;
const init = element => {
  const carouselElements = element && element.getElementsByClassName('js-carousel') || [];
  const carousels = [];
  const onBeforeUnload = () => {
    while (carousels.length) {
      carousels.pop().destroy();
    }
  };
  [...carouselElements].forEach(el => {
    var _svs;
    const {
      initialized
    } = el.dataset;
    const {
      align,
      cardSize,
      gutter,
      itemsPerPage
    } = ((_svs = svs) === null || _svs === void 0 || (_svs = _svs.pix_mix) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 ? void 0 : _svs.carouselData) || {};
    if (!initialized) {
      carousels.push(new CarouselManager(el, {
        align,
        cardSize,
        gutter,
        itemsPerPage
      }));
      el.dataset.initialized = 'true';
      window.addEventListener('beforeunload', onBeforeUnload);
    }
  });
};
init(document);
setGlobal('svs.components.lbUi.carousel.init', init);

 })(window);