(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/carousel/assets/javascripts/helpers/handlebars-helper.js') >= 0) return;  svs.modules.push('/components/lb-ui/carousel/assets/javascripts/helpers/handlebars-helper.js');

'use strict';

function registerHelpers(hbs) {
  hbs.registerHelper('zeroBasedPageLength', (items, itemsPerPage) => Math.ceil(items / itemsPerPage) - 1);
  hbs.registerHelper('defaultValue', (value, defaultValue) => value || defaultValue);
  hbs.registerHelper('getOffset', function (index) {
    let selected = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return Math.abs(index - selected);
  });
}
if (svs.isServer) {
  registerHelpers(require('hbs'));
} else {
  registerHelpers(Handlebars);
}

 })(window);