(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/carousel/views/carousel.js') >= 0) return;  svs.modules.push('/components/lb-ui/carousel/views/carousel.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.lb_ui=_svs.lb_ui||{};
_svs.lb_ui.carousel=_svs.lb_ui.carousel||{};
_svs.lb_ui.carousel.templates=_svs.lb_ui.carousel.templates||{};
svs.lb_ui.carousel.templates.carousel = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " carousel-mobile";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " carousel-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"branding") || (depth0 != null ? lookupProperty(depth0,"branding") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"branding","hash":{},"data":data,"loc":{"start":{"line":3,"column":29},"end":{"line":3,"column":41}}}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " carousel-nav-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"nav") || (depth0 != null ? lookupProperty(depth0,"nav") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"nav","hash":{},"data":data,"loc":{"start":{"line":4,"column":28},"end":{"line":4,"column":35}}}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"className") || (depth0 != null ? lookupProperty(depth0,"className") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"className","hash":{},"data":data,"loc":{"start":{"line":5,"column":21},"end":{"line":5,"column":34}}}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cardSize") || (depth0 != null ? lookupProperty(depth0,"cardSize") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cardSize","hash":{},"data":data,"loc":{"start":{"line":9,"column":42},"end":{"line":9,"column":54}}}) : helper)))
    + "px";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"carousel js-carousel"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMobile") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":2,"column":43}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"branding") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":3,"column":49}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"nav") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":4,"column":43}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"className") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":5,"column":42}}})) != null ? stack1 : "")
    + "\"\n  style=\"\n    --carousel-align: "
    + alias4(((helper = (helper = lookupProperty(helpers,"align") || (depth0 != null ? lookupProperty(depth0,"align") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"align","hash":{},"data":data,"loc":{"start":{"line":7,"column":22},"end":{"line":7,"column":31}}}) : helper)))
    + "\n    --carousel-per-page: "
    + alias4(((helper = (helper = lookupProperty(helpers,"itemsPerPage") || (depth0 != null ? lookupProperty(depth0,"itemsPerPage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"itemsPerPage","hash":{},"data":data,"loc":{"start":{"line":8,"column":25},"end":{"line":8,"column":41}}}) : helper)))
    + "\n    --carousel-card-size: "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cardSize") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":26},"end":{"line":9,"column":63}}})) != null ? stack1 : "")
    + "\n  \"\n>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-carousel-carousel_navigation"),depth0,{"name":"components-lb_ui-carousel-carousel_navigation","hash":{"selectedIndex":(lookupProperty(helpers,"defaultValue")||(depth0 && lookupProperty(depth0,"defaultValue"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"selectedIndex") : depth0),0,{"name":"defaultValue","hash":{},"data":data,"loc":{"start":{"line":12,"column":70},"end":{"line":12,"column":100}}})},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <div class=\"carousel-items js-carousel-items\" data-testid=\"carousel-items\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"usePartial":true,"useData":true});
Handlebars.partials['components-lb_ui-carousel-carousel'] = svs.lb_ui.carousel.templates.carousel;
})(svs, Handlebars);


 })(window);