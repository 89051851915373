(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/carousel/views/carousel-item.js') >= 0) return;  svs.modules.push('/components/lb-ui/carousel/views/carousel-item.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.lb_ui=_svs.lb_ui||{};
_svs.lb_ui.carousel=_svs.lb_ui.carousel||{};
_svs.lb_ui.carousel.templates=_svs.lb_ui.carousel.templates||{};
svs.lb_ui.carousel.templates.carousel_item = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " carousel-item-selected";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"html") || (depth0 != null ? lookupProperty(depth0,"html") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"html","hash":{},"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":16}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"carousel-item js-carousel-item"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSelected") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":42},"end":{"line":1,"column":90}}})) != null ? stack1 : "")
    + "\">\n  <div class=\"carousel-item-inner\" style=\"--offset:"
    + container.escapeExpression((lookupProperty(helpers,"getOffset")||(depth0 && lookupProperty(depth0,"getOffset"))||container.hooks.helperMissing).call(alias1,(data && lookupProperty(data,"index")),(depth0 != null ? lookupProperty(depth0,"selectedIndex") : depth0),{"name":"getOffset","hash":{},"data":data,"loc":{"start":{"line":2,"column":51},"end":{"line":2,"column":85}}}))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"html") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"usePartial":true,"useData":true});
Handlebars.partials['components-lb_ui-carousel-carousel_item'] = svs.lb_ui.carousel.templates.carousel_item;
})(svs, Handlebars);


 })(window);